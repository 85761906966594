<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Group</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="Group Name"
                      :rules="'required|alpha_num'"
                      v-slot="{ errors, classes }"
                    >
                      <label>Name *</label>
                      <input
                        type="text"
                        placeholder="Group Name"
                        v-model="group.name"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Description *</label>
                    <ValidationProvider
                      name="Group Description"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="Group Description"
                        v-model="group.description"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      group: {
        name: "",
        description: "",
        id: 0,
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.GROUP_ADD,
    };
  },
  created: function () {
    this.loadGroupData();
  },
  methods: {
    onSubmit: function () {
      const self = this;
      AxiosService.post(this.url, this.group).then((result) => {
        if (
          _.has(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.GROUP_ACTIVITY,
              this.title,
              AuditListner.groupAuditTemplate(self.group)
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.GROUP_URI);
        }
      });
    },
    loadGroupData: function () {
      var groupId = this.$route.query.id;
      const self = this;
      if (groupId != undefined) {
        AxiosService.get(Constant.url.GROUP_GET + groupId).then((result) => {
          self.group = result;
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.GROUP_UPDATE;
      }
    },
  },
};
</script>
<style scoped></style>
